.toast-warning > .toast-message {
    color: black;
}

.page-item {
    cursor: pointer;
}

.disabled {
    cursor: not-allowed;
    color: black;
}

.table {
    display: block;
    overflow-x: auto;
    white-space: nowrap;
}
.pagination {
    overflow-x: auto;
    white-space: nowrap;
    margin-bottom: 0px;
}

@font-face {
    font-family: 'Consolas';
    src: url('../public/fonts/Consolas.ttf') format('truetype');
}
.console-container > * {
	font-family: 'Consolas';
}
.console-header {
    height: 50px;
    margin: auto;
}
.console-output {
    max-height: 67vh;
    width: 100%;
    min-height: 100px;
    overflow: scroll;
    border: 3px solid black;
    margin: auto;
    background-color: #31363b;
    color: white;
}
.console-input {
    flex: 0 1 40px;
    width: 100%;
    margin: auto;
}
.console-button {
    padding-top: 0px;
    margin-top: 5px;
    margin-right: 2px;
    height: 40px;
    background-color: black;
    color: white;
}
.console-checkbox {
    margin-left: 5px;
    margin-right: 5px;
}
.back {
    margin-bottom: 10px;
}